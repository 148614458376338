import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
import Testimonial from '../../components/Testimonial';
import { callService } from '../../../data/SiteConfig';
import { printGbpWithUsd } from '../../utils/formatting';
import ContactForm from '../../components/ContactForm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Who’s this for?`}</h2>
    <p>{`You’re a senior technology decision maker who can see the benefits that adopting serverless could bring to your organisation’s delivery of software projects. But you’re not sure about how some of the specifics will look in practice.`}</p>
    <h2>{`What I can help you with`}</h2>
    <p>{`You need experienced, independent and pragmatic insight on how you could make this transition. Here are some of the areas I can help you with:`}</p>
    <ul>
      <li parentName="ul">{`Identifying what projects or products within your organisation would be the best fit to pilot serverless on`}</li>
      <li parentName="ul">{`What specific AWS services to focus on and how they fit together`}</li>
      <li parentName="ul">{`What tools and frameworks to use`}</li>
      <li parentName="ul">{`How to implement specific application use cases in AWS serverless`}</li>
      <li parentName="ul">{`How to test your AWS serverless workload`}</li>
      <li parentName="ul">{`Designing a release pipeline for your app`}</li>
    </ul>
    <p>{`In this 1 hour, 1-on-1 video call, I can help you answer questions in these areas or any others you may have.`}</p>
    <p>
  Price:{' '}
  <span className="font-bold">{printGbpWithUsd(callService.priceGBP)}</span>.
    </p>
    <div className="text-center mt-10 lg:mt-2 mb-10 mx-auto font-sans">
  <a href={callService.bookingUrl} className="inline-block btn px-4 py-2 bg-primary-600 hover:bg-primary-500 text-white hover:text-white rounded-md mb-2" target="_blank">
    Book Call Now
  </a>
  <p className="text-gray-700 text-base">
    You can pay and reserve your time slot straight away during my working hours
    of 9am to 5pm Irish Time, Mon–Fri. There are a few open slots each month. If, after
    this call, you decide you wish to do a longer engagement such as an
    Application Roadmap with me, the cost of this call will be deducted from
    that engagement.
  </p>
    </div>
    <Testimonial authorName="Jon Farmand" authorTitle="CTO at inOrbit" mdxType="Testimonial">
  <div>
    <p>
      <strong>I feel much more confident after the call</strong> that my
      application can be developed with serverless in mind, using the services
      that I was planning on using plus a few more.
    </p>
    <p>
      I have chosen to use Serverless for a new startup I'm working on and I'm
      fairly new to it so I wanted to talk through some of the architectural
      decisions with an expert.
    </p>
    <p>
      We talked through how to implement a specific workflow of my application
      using serverless, as well as ways to attack some of the trickier use cases
      in a straightforward manner. After the meeting, Paul sent me some
      resources that I can refer to in the future which will prove immensely
      beneficial. Well worth it!
    </p>
  </div>
    </Testimonial>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      